import React from 'react';
import Layout from '../../containers/gatsby/layout';
import Credibility from '../../containers/sections/Credibility/Credibility';
import ServiceBlurb from '../../containers/services/ServiceBlurb/ServiceBlurb';
import ServiceFAQs from '../../containers/services/ServiceFAQs/ServiceFAQs';
import BookOnlineContainer from '../../containers/sections/BookOnline/BookOnlineContainer';
import ServicesGallery from '../../containers/services/ServicesGallery/ServicesGallery';
import SEO from '../../containers/gatsby/seo';
import ServiceHeroContainer from '../../containers/heroes/ServiceHero/ServiceHeroContainer';

const MovingFlatPage = (props) => (
  <Layout overlayHeader={true} location={props.location}>
    <SEO title="London's Favourite Flat Movers" keywords={[`move flat london`, `flat removals`, `apartment moves london`, `same-day flat moves`, `emergency flat move`, `man and van move flat`, `move flat`, `moving apartment`]} />
    <ServiceHeroContainer
      jobName={'Flat Move'}
      title={'Move Flat or Apartment'}
      description={'Moving flat? We do flat removals in London everyday, and we can be there within 1 hour of booking'}
      heroImg={require('../../assets/services-heroes/apartments.jpg')}
      reviewImage={require('../../assets/avatars/2.png')}
      reviewContent={'I am so happy with my experience of this company! I was let down on moving day by a man+van off gumtree. I phoned these guys in a panic and they assured me they never let customers down... So I got the app and they sorted me with a driver so quickly, at a great price too. The driver himself was very helpful with a lovely attitude - just like the guy on the phone. Thank u guys!'}
      reviewName={'Ellie Matthews'}
    />
    <Credibility />
    <ServiceBlurb
      blurbTitle={'Moving flat? We got you.'}
      blurb={'We find professional man-and-van drivers in your area to help with flat moves in London everyday. We\'re quickly becoming London\'s favourite removal company with unbeatable prices.'}
      blurb2={'If it\'s just you moving into a new flat, we recommend choosing one of our Medium vans - it will fit a double bed, desk, chairs, TV, and more.'}
      blurb3={'If you\'re moving flat with 2, 3, or more residents, choose our Luton-sized van (XL) when you book. Make sure you\'ve got everything packed and ready so we can fit it into the van more easily. Get bulky, solid items ready first - then load smaller and more delicate items on top. All the space in the van is yours.'}
    />
    <ServicesGallery
      gallerySubtitle={'Moving flat is becoming easier and affordable with JJD'}
      photos={[
        require('../../assets/service-gallery/1.jpg'),
        require('../../assets/service-gallery/2.png'),
        require('../../assets/service-gallery/3.jpg'),
        require('../../assets/service-gallery/4.jpg'),
        require('../../assets/service-gallery/5.jpg'),
        require('../../assets/service-gallery/6.jpg'),
        require('../../assets/service-gallery/8.jpg'),
        require('../../assets/service-gallery/9.png'),
        require('../../assets/service-gallery/10.png'),
        require('../../assets/service-gallery/11.jpg'),
        require('../../assets/service-gallery/12.jpg'),
        require('../../assets/service-gallery/13.jpg'),
        require('../../assets/service-gallery/14.jpg'),
        require('../../assets/service-gallery/15.jpg'),
        require('../../assets/service-gallery/16.jpg'),
        require('../../assets/service-gallery/17.jpg'),
        require('../../assets/service-gallery/18.jpg'),
        require('../../assets/service-gallery/19.jpg'),
        require('../../assets/service-gallery/20.jpg'),
        require('../../assets/service-gallery/21.jpeg'),
        require('../../assets/service-gallery/22.jpeg'),
        require('../../assets/service-gallery/23.jpeg'),
      ]}
    />
    <ServiceFAQs
      faqTitle={'FAQs'}
      faqSubTitle={'Got a question about flat moves?'}
      faqs={[
        {
          question: 'How much will it cost?',
          answer: 'Flat or apartment moves generally cost around £40 to £120, depending on how long your journey is. If you don\'t need any help lifting, the price will be cheaper - just specify how much lifting help you need in the booking process.'
        },
        {
          question: 'I\'m moving into a new place with a friend who lives elsewhere, what do I do?',
          answer: 'Just add an additional stop to load their stuff along the way! You can add up to 4 additional stops when you book with fgo.'
        },
        {
          question: 'How do I contact my driver?',
          answer: 'Your driver\'s name and mobile number will be emailed to you as soon as we\'ve allocated the job. Just give \'em a call. If your using the JJD app, you\'ll see their details on the \'My bookings\' screen.'
        },
        {
          question: 'What if my driver breaks down?',
          answer: 'We will sort it all out with a replacement straight away at no extra cost. Be aware that some cheap removal companies that you find online will ditch your job as soon as they\'ve found a better one, and tell you they\'ve broken down. A lot of our jobs at JJD come in from people who have been let down by other companies on moving day.'
        },
        {
          question: 'I need to move this weekend, are you available?',
          answer: 'Yes, JJD is available 24/7 - even one hour from now. Just choose an arrival date and time when you book.'
        }
      ]}
    />
    <BookOnlineContainer />
  </Layout>
);

export default MovingFlatPage;
